import http from "./support";
export default {
  getDoclist(group, page, query) {
    return http.get("newgetDocList", {
      group: group,
      page: page,
      tags: query
    });
  },
  getDocGroup() {
    return http.get("newgetGroupClass", {});
  },
  getDocPage(method, id) {
    return http.get("newgetDocsInfor", {
      method: method,
      id: id
    });
  }
}
