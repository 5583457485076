import "core-js/modules/es.promise";
import pb from "@/service/Public";
import _ from "lodash";
import { createApp, h } from "vue";
import Vue from "vue";
import App from "@/App.vue";
const app = createApp(App);
import axios from "axios";
// state
let state = {
	window: {
		width: 0,
		method: "lg",
	},
	ScrollTop: 0,
	Tags: [],
	pagecache: false,
	pagepatch: "",
	header: {
		title: "",
		meta: [],
	},
	Index: {
		banner: [],
		hotssearch: [],
		hotClass: [],
		hotBook: [],
		memberlist: [],
		cloudClass: {},
		examclass: [],
		funclist: [],
		countdown: null,
	},
	postcode: [],
	City: [],
	openlogin: false,
	showreport: false,
	Login: {
		isLogin: false,
		account: "",
		api_token: "",
		email: "",
		homephone: "",
		id: "",
		location: "",
		name: "",
		open: "",
		phone: "",
		postelcode: "",
		uid: "",
	},
  Voucher:[]
};

// getters
const getters = {
	getMeta(state) {
		let meta = [];
		_.each(state.header.meta, (item, keys) => {
			const tmps = {
				name: keys,
				context: item,
			};
			meta.push(tmps);
		});
		// console.log(state.header.meta, meta);
		return [state.header.meta, meta];
	},
	getpostcode(state) {
		return (postuide) => {
			return _.filter(state.postcode, {
				area_uid: postuide,
			})[0];
		};
	},
};

// actions
const actions = {
	async getSevenShop({ commit }, data) {
		return await pb
			.getSevenShop(data)
			.then((data) => {
				return data.data;
			})
			.catch((error) => {
				console.log(error);
				return {
					state: false,
					result: "Message",
				};
			});
	},
	pageloaded({ state }) {
		// console.log(state.pagepath);
		// if (process.env.NODE_ENV && process.env.NODE_ENV == "production" && state.pagecache) {
		//   console.log(window.prerenderReady, "TTR");
		//   axios.post("https://api.prerender.io/recache", {
		//     prerenderToken: process.env.VUE_APP_prerenderToken,
		//     url: process.env.VUE_APP_LOCAL + state.pagepath
		//   });
		// }
		window.prerenderReady = true;
		return;
	},
	resetPageInfo({ commit }, data) {
		const sa = {
			title: data.title,
			meta: {
				keywords: data.keywords || "",
				description: data.description || "",
			},
		};
		commit("setHeaderinfo", sa);
	},
	async getPageInfo({ commit, dispatch }, data) {
		const noset = data.noset || false;
		const result = pb.getPageHeader(data.url);
		await result.then((data) => {
			if (!noset) {
				if (data.data.state) {
					dispatch("resetPageInfo", data.data.data);
				} else {
					commit("setHeaderinfo", {
						title: "",
						meta: {
							keywords: process.env.VUE_APP_keywords,
							description: process.env.VUE_APP_description,
						},
					});
				}
			}
			return;
		});
		return result;
	},
	checkmember({ commit}) {
		const result = pb.checkmember();
		result
			.then((data) => {
				commit("setmember", {
					Login: true,
					data: data.data.Member,
          Voucher:data.data.Voucher
				});


			})
			.catch(() => {});
		return result;
	},
	logout({ commit }) {
		localStorage.Authorization = "";
		commit("setmember", {
			Login: false,
			data: {
				account: "",
				api_token: "",
				email: "",
				homephone: "",
				id: "",
				location: "",
				name: "",
				open: "",
				phone: "",
				postelcode: "",
				uid: "",
			},
      Voucher:[]
		});
	},
	async getpostcode({ commit }) {
		const postcode = await pb.getpostcode().then((data) => {
			return data.data;
		});
		const City = await pb.getCity().then((data) => {
			return data.data.result;
		});
		commit("setpostcode", postcode);
		commit("setCity", City);
		// result.then((data) => {
		//   commit("setpostcode", data.data)
		// })
		// return result;
	},
	getIndex({ commit }) {
		const result = pb.getIndex();
		result.then((data) => {
			// console.log("155",data.data);
			commit("setIndexData", data.data.result);
		});
		return result;
	},
	showToast({ commit }, { vm, title, method, text, time, toaster }) {
		vm.$bvToast.toast(text, {
			headerClass: title ? "" : "d-none",
			title: title,
			variant: method || "info",
			toaster: toaster || "b-toaster-top-right",
			solid: true,
			// noAutoHide:true
			autoHideDelay: time || 10000,
		});
	},
	Login({ commit, dispatch }, tdata) {
		const vm = tdata.vm;
		const req = _.cloneDeep(tdata);
		delete req.vm;
		const result = pb.Login(req);
		result.then((data) => {
			let alertToast = {
				vm: vm,
				title: "",
				method: "",
				text: "",
				toaster: "b-toaster-top-center b-toast-top-10",
				time: 2 * 1000,
			};
			// const h = (new Vue()).$createElement;
			switch (data.data.status) {
				case "open":
					localStorage.Authorization = data.data.member.api_token;
					alertToast.title = "登入成功";
					alertToast.method = "success";
					alertToast.text = h("b", {}, "歡迎" + data.data.member.name + "學員！");
					alertToast.time = 800;
					// commit("setLogin", true);
					dispatch("checkmember");
					break;
				case "no-open":
					alertToast.title = h(
						"h6",
						{
							class: ["my-2 mx-auto pl-4"],
						},
						["糟糕！無法登入"]
					);
					alertToast.method = "danger";
					alertToast.text = h(
						"p",
						{
							class: ["mb-0 font-up-1 text-center"],
						},
						[
							h("span", {}, "您的帳號尚未開通，請聯絡我們！"),
							h(
								"a",
								{
									class: ["border-bottom border-dark mb-1"],
									attrs: {
										href: "tel:0227338118",
									},
								},
								"諮詢專線: 02-27338118"
							),
						]
					);
					alertToast.time = 10 * 1000;
					// "您的會員帳號目前尚未開通，請聯絡我們由專人為您服務！<b>諮詢專線: 02-27338118</b>";

					break;
				// case "canchangepwd":
				//    alertToast.title = h("h6", {
				//      class: ['my-2 mx-auto pl-4']
				//    }, ["您的密碼安全性過低，請您"]);
				//   break;
				case "noBindLine":
					alertToast.method = "warning";
					alertToast.title = h(
						"h6",
						{
							class: ["my-2 mx-auto pl-4"],
						},
						["您的Line尚未綁定"]
					);
					alertToast.text = h(
						"p",
						{
							class: ["mb-0 font-up-1"],
						},
						["請登入會員並將「登入及綁定馬跡會員」打勾"]
					);
					break;

				case "nojoinmember":
					alertToast.title = h(
						"h6",
						{
							class: ["my-2 mx-auto pl-4"],
						},
						["糟糕！無法登入"]
					);
					alertToast.method = "danger";
					alertToast.time = 17 * 1000;
					alertToast.text = h(
						"p",
						{
							class: ["mb-0 font-up-1"],
						},
						[
							h(
								"ul",
								{
									class: ["p-0 m-0"],
									attrs: {
										style: "list-style-type:none",
									},
								},
								[
									h(
										"li",
										{
											class: ["py-1 pl-3"],
										},
										[
											h("span", {}, "未加入會員，請先"),
											h(
												"a",
												{
													class: ["cur-print px-1 border-bottom border-dark"],
													attrs: {
														href: "/register",
													},
												},
												"註冊會員"
											),
										]
									),
									h(
										"li",
										{
											class: ["py-1 pl-3"],
										},
										[
											h("span", {}, "已加入會員，無法登入時"),
											h(
												"a",
												{
													class: ["cur-print px-1 border-bottom border-dark"],
													attrs: {
														href: "/changepw",
													},
												},
												"忘記密碼"
											),
										]
									),
									h(
										"li",
										{
											class: ["py-1 text-center mb-1"],
										},
										[
											h(
												"span",
												{
													class: ["text-deepgold"],
												},
												"客服專線"
											),
											h(
												"a",
												{
													class: ["px-1 text-deepgold border-bottom border-dark"],
													attrs: {
														href: "tel:0227338118",
													},
												},
												"022733-8118"
											),
										]
									),
								]
							),
						]
					);
					break;
			}
			alertToast.text = [alertToast.text];
			if (data.data.status != "open") {
				dispatch("showToast", alertToast);
			}
		});
		return result;
	},
};

// mutations
const mutations = {
	setLoginpage(state, data) {
		state.openlogin = data;
	},
	setreportpage(state, data) {
		state.showreport = data;
	},
	setScrollTop(state, data) {
		state.ScrollTop = data;
	},

	setIndexData(state, data) {
		const forField = {
			WebMemberserver: "memberlist",
			getWebNews: "hotssearch",
			getBanner: "banner",
			new_WebContent: "hotClass",
			getIndexBook: "hotBook",
			getWebOnline: "cloudClass",
			WebExam: "examclass",
			WebFunclist: "funclist",
			getCountDown: "countdown",
			//   "getAd": ""
		};
		_.forEach(forField, (item, keys) => {
			if (data[keys]) {
				state.Index[item] = data[keys];
			}
		});
		// state.memberlist;
	},
	setpostcode(state, data) {
		state.postcode = data;
	},
	setCity(state, data) {
		state.City = data;
	},
	setLogin(state, data) {
		state.Login.isLogin = data;
	},
	setmember(state, data) {
		_.each(state.Login, (item, keys) => {
			if (keys != "isLogin") {
				state.Login[keys] = data.data[keys];
			}
		});
		state.Login.isLogin = data.Login;
    state.Voucher = data.Voucher;
	},
	setHeaderinfo(state, data) {
		state.header.title = data.title ? data.title + "-" + process.env.VUE_APP_TITLE : process.env.VUE_APP_TITLE;
		const tmpmeta = [
			{
				vmid: "keywords",
				name: "keywords",
				content: data.meta.keywords || process.env.VUE_APP_keywords,
			},
			{
				vmid: "description",
				name: "description",
				content: data.meta.description || process.env.VUE_APP_description,
			},
			{
				vmid: "og:title",
				property: "og:title",
				content: state.header.title,
			},
			{
				vmid: "og:description",
				property: "og:description",
				content: data.meta.description || process.env.VUE_APP_description,
			},
			{
				vmid: "og:url",
				property: "og:url",
				content: process.env.VUE_APP_LOCAL + state.pagepath,
			},
			// {
			//   property: 'og:locale',
			//   content: 'zh_TW'
			// }
		];
		// console.log(tmpmeta);
		state.header.meta = tmpmeta;
		// state.header.meta = _.map(data.meta, (item, keys) => {
		//  _.map(seth => {
		//     if (seth.name == keys) {
		//       seth.content = item;
		//       // console.log(seth.meta);
		//     }
		//     if (seth.property == keys) {
		//       seth.content = item;
		//       // console.log(seth.meta);
		//     }
		//     return seth;
		//   })
		// });
	},
	setpagecache(state, data) {
		state.pagecache = data.pagecache;
		state.pagepath = data.pagepath;
	},
	setwindowmethod(state, { width }) {
		// { lg: 1024, md: 768, sm: 368 };
		let method = "sm";
		if (width >= 1024) {
			method = "lg";
		} else if (width >= 768) {
			method = "md";
		}
		state.window.width = width;
		state.window.method = method;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
