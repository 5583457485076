// import Vue from 'vue';
// import Vuex from 'vuex';
import { createStore } from 'vuex'
import getters from './store/getters';
import Pro from '@/store/modules/Pro';
import Public from '@/store/modules/Public';
import member from '@/store/modules/member';
import docs from '@/store/modules/docs';
// Vue.use(Vuex);

export default createStore({
  modules: {
    Pro,
    Public,
    member,
    docs
  },
  getters,

});
