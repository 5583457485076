// import "core-js/modules/es.promise";
// import Vue from "vue";
import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// import "./plugins/axios";
import axios from "axios";
import { mdbInput } from "mdbvue";

// import 'babel-polyfill'
import "@/style/index.scss";
import "@/style/css2.scss";
import VueSimpleAlert from "vue-simple-alert";
import "@/plugins/fontawesome";
import "bootstrap-icons/font/bootstrap-icons.css";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import BVALL from "bootstrap-vue";
import VueCurrencyFilter from "vue-currency-filter";
import VueMoment from "vue-moment";
import VueAnalytics from "vue-analytics";
import "@/directive/directive.js";

import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

import moment from "moment";
import "moment/locale/zh-tw";

import VueJsonLD from "vue-jsonld";
import Meta from "vue-meta";

// import { createGtm } from 'vue-gtm'
import { createGtm } from "@gtm-support/vue-gtm";
import VueGtag from "vue-gtag-next";

// import VueSignaturePad from 'vue-signature-pad';
// Vue.use(VueSignaturePad);
const app = createApp(App);
app.component(mdbInput);
// FontAwesomeIcon,FontAwesomeLayers,FontAwesomeLayersText
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("font-awesome-layers", FontAwesomeLayers);
app.component("font-awesome-layers-text", FontAwesomeLayersText);
app.use(VueJsonLD);
app.use(Meta, {
	keyName: "metaInfo",
	attribute: "data-vue-meta",
	ssrAttribute: "data-vue-meta-server-rendered",
	tagIDKeyName: "vmid",
	refreshOnceOnNavigation: true,
});

app.component("v-select", vSelect);
app.use(VueSimpleAlert, {
	reverseButtons: true,
	confirmButtonText: "送出",
	cancelButtonText: "取消",
});
app.component(BootstrapVue);
app.use(BootstrapVue);
app.use(BootstrapVueIcons);
app.use(VueMoment, {
	moment,
});
// app.config.globalProperties.$moment = moment

app.config.productionTip = false;
app.use(VueCurrencyFilter, {
	symbol: "$",
	thousandsSeparator: ",",
	fractionCount: 0,
	fractionSeparator: ",",
	symbolPosition: "front",
	symbolSpacing: true,
});
// 33450615-1
const isProd = ["production", "staging"].indexOf(process.env.NODE_ENV) > -1;
// app.use(VueAnalytics, {
// 	id: process.env.APP_ANY,
// 	router,
// 	debug: {
// 		enabled: !isProd,
// 		sendHitTask: isProd,
// 	},
// 	autoTracking: {
// 		pageviewOnLoad: false,
// 	},
// });
console.log(process.env.VUE_APP_ANY);
// app.use(
// 	createGtm({
// 		id: process.env.VUE_APP_ANY,
// 		debug: !isProd,
// 		vueRouter: router,
// 		trackOnNextTick: false,
// 	})
// );
app.use(VueGtag, {
	property: {
		id: "G-LZY8ZNN7LN",
	},
	useDebugger: true,
});
// axios.defaults.headers.common["Authorization"] = (localStorage.Authorization) ?
//   "Bearer " + (localStorage.Authorization) : "";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["company"] = process.env.VUE_APP_COMPANY;
store.dispatch("Pro/getCart");
store.dispatch("Public/checkmember");

// const liffinfo = {
// 	userId: "",
// 	isLiff: liff.isInClient(),
// };
// store.commit("member/setLiff", liffinfo);

app.use(VueAnalytics, {
	// id: 'UA-XXX-X',
	id: process.env.VUE_APP_ANY,
	router,
	debug: {
		enabled: !isProd,
		sendHitTask: isProd,
	},
	autoTracking: {
		pageviewOnLoad: false,
	},
	ecommerce: {
		enabled: true,
		enhanced: true,
	},
	set: [
		{
			field: "currencyCode",
			value: "TWD",
		},
	],
});
// Vue.use(createGtm, {
//   id: 'GTM-KQJDQWP', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
//   defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   // debug: false, // Whether or not display console logs debugs (optional)
//   loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//   trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
// });
router.afterEach((to, from) => {
	// console.log(trackService);
	setTimeout(() => {
		// trackService.pageUserTrack()
	}, 500);
});
const resize = () => {
	store.commit("Public/setwindowmethod", {
		width: window.innerWidth,
	});
};
window.addEventListener("resize", resize);
resize();

app.use(store).use(router).mount("#app");
// new Vue({
// 	router,
// 	store,
// 	render: (h) => h(App),
// 	mounted: () => document.dispatchEvent(new Event("x-app-rendered"), new Event("render-event")),
// }).$mount("#app");
