import http from "./support";
import axios from "axios";
import { FormDatepickerPlugin } from "bootstrap-vue";
export default {
	getIndex(method) {
		return http.get("newgetIndex");
	},
	getpostcode() {
		return http.get("getpost");
	},
	Login(tdata) {
		const data = http.encrypt(tdata);
		return http.post("newLogin", {
			data: data,
		});
	},
	checkmember() {
		return http.get("getmember");
	},
	getPageHeader(url) {
		return http.get("PageInfo", {
			url: url,
		});
	},
	getCity() {
		return http.get("getCity");
	},
	getSevenShop(data) {
		// const formdata = new FormData;
		// _.each(data, (item, keys) => {

		//   formdata.append(keys, item);
		// });
		return http.get("getSevenShop", data);
		return axios({
			method: "POST",
			url: "https://www.ibon.com.tw/retail_inquiry_ajax.aspx",
			form: data,
			// responseType: 'text',
			headers: {
				"content-type": "application/x-www-form-urlencoded",
				"X-Requested-With": "XMLHttpRequest",
				"Access-Control-Allow-Origin": "*",
			},
		});
	},
};
