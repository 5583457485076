import store from './store';

let getPage = (name, path = "views") => () => {
  return import(`./${path}/${name}.vue`);
};

const paths = [{
    path: '/',
    name: "home",
    components: {
      // header: getPage("Header", "components"),
      header: getPage("Header", "components"),
      indexBody: getPage("Index"),
      footer: getPage("Footer", "components")
    },
    // component:Header,
    meta: {}
  },
  {
    path: '/index',
    name: "index",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("Index"),
      footer: getPage("Footer", "components")
    },
    meta: {}
  }, {
    path: '/product/:type',
    name: "ProductList",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("page/ProList"),
      footer: getPage("Footer", "components")
    },
    meta: {

    }
  },
  {
    path: '/companyinfo',
    name: "CompanyInfo",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("page/companyinfo"),
      footer: getPage("Footer", "components")
    },
    meta: {

    }
  },
  {
    path: '/product/goodsmore/:Pro',
    name: "ProInfoOne",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("page/ProInfo"),
      footer: getPage("Footer", "components")
    },
    meta: {
      method: "book",
    }
  },
  {
    path: '/product/goodsmore/:Pro/:group',
    name: "ProInfoMore",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("page/ProInfo"),
      footer: getPage("Footer", "components")
    },
    meta: {
      method: "class",
    }
  },
  {
    path: '/cart',
    name: "Cart",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("page/Cart"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/orderstatus/:uid',
    name: "OrderStatus",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("Cart/CartPage", "components"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/payment/:uid',
    name: "PayCard",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("Cart/Payment", "components"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path:"/paymentfinish",
    name:"PaymentFinish",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("Cart/PaymentFinish", "components"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/cart/ordersuccess',
    name: "CartEnd",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("Cart/CartPage", "components"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/register',
    name: "register",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("member/register"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  // {
  //   path: '/searchweb',
  //   name: "searchweb",
  //   components: {
  //     header: getPage("Header", "components"),
  //     indexBody: getPage("page/searchweb"),
  //     footer: getPage("Footer", "components")
  //   },
  //   meta: {
  //     noCache: true
  //   }
  // },
  {
    path: '/member/activate',
    name: "success",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("member/membersuccess"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/memberopenLogin/:token',
    name: "Loginsuccess",
    meta: {
      noCache: true
    }
    // components: {
    //   header: getPage("Header", "components"),
    //   indexBody: getPage("member/membersuccess"),
    //   footer: getPage("Footer", "components")
    // },

  },
  {
    path: '/order/refund/:refund',
    name: "Paysuccess",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("member/returnpay"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/forgetpw',
    name: "forgetpw",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("member/forgetpw"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/getmeberopen',
    name: "getmeberopen",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("member/getmeberopen"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/logout',
    name: "logout",
    meta: {
      noCache: true
    }
  },
  {
    path: '/login',
    name: "login",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("member/login"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/404',
    name: "notfound",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("notfound", "components"),
      footer: getPage("Footer", "components")
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/docs/:method',
    name: "docs",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("page/docslist"),
      footer: getPage("Footer", "components")
    },
    meta: {
      // noCache:false
    }
  },
  {
    path: '/docs/news/:id',
    redirect: (to, from) => {
      return `/docs/exam_notes/${to.params.id}`;
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/docs/anya/:id',
    redirect: (to, from) => {
      return `/docs/share/${to.params.id}`;
    },
    meta: {
      noCache: true
    }
  },
  {
    path: '/docs/:method/:id',
    name: "docspage",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("page/docpage"),
      footer: getPage("Footer", "components")
    },
    meta: {}
  },
  {
    path: "/istudy",
    name: "istudy",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("member/istudy"),
      footer: getPage("Footer", "components")
    },
  }, {
    path: "/istudy/getlisten",
    name: "getlisten",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("member/getlisten"),
      footer: getPage("Footer", "components")
    }
  },
  {
    path: "/member",
    components: {
      header: getPage("Header", "components"),
      indexBody: getPage("member/backmember"),
      footer: getPage("Footer", "components")
    },
    meta: {
      checkmember: true,
      noCache: true
    },
    children: [{
        path: "info",
        name: "member",
        components: {
          MemberMenu: getPage("member/back/leftmenu"),
          MemberBody: getPage("member/back/page/info")
        }
      },
      {
        path: "chpwd",
        name: "chpwd",
        components: {
          MemberMenu: getPage("member/back/leftmenu"),
          MemberBody: getPage("member/back/page/chpwd")
        }
      },{
        path: "mindex",
        name: "mindex",
        components: {
          // MemberMenu: getPage("member/back/leftmenu"),
          MemberBody: getPage("member/back/page/index")
        }
      },
      {
        path: "ordersearch",
        name: "ordersearch",
        components: {
          MemberMenu: getPage("member/back/leftmenu"),
          MemberBody: getPage("member/back/page/ordersearch")
        }
      },
      {
        path: "application",
        name: "application",
        components: {
          MemberMenu: getPage("member/back/leftmenu"),
          MemberBody: getPage("member/back/page/courseleave")
        }
      },
      {
        path: "service",
        name: "service",
        components: {
          MemberMenu: getPage("member/back/leftmenu"),
          MemberBody: getPage("member/back/page/service")
        }
      },
      {
        path: "/class",
        redirect: "/product/class",
        meta: {
          noCache: true
        },
      },
      {
        path: "/book",
        redirect: "/product/book",
        meta: {
          noCache: true
        },
      },
      {
        path: "/index.html",
        redirect: "/index",
        meta: {
          noCache: true
        },
      },
      {
        path: "/prouduct/goodsmore/:Pro",
        redirect: (to, from) => {
          return "/product/goodsmore/" + to.params.Pro
        },
        meta: {
          noCache: true
        },
      },
      {
        path: "/prouduct/goodsmore/:Pro/:group",
        redirect: (to, from) => {
          return "/product/goodsmore/" + to.params.Pro + "/" + to.params.group
        },
        meta: {
          noCache: true
        },
      },
      // {
      //   path: '/news/:method/:id',
      //   meta: {
      //     noCache: true
      //   },
      //   redirect: (to, from) => {
      //     let toa = "";
      //     const temp = {
      //       DG1: "exam_notes",
      //       DG2: "share",
      //       DG3: "share",
      //       DG4: "teacher",
      //       DG5: "rule",
      //       DG6: "exam_notes",
      //     };
      //     return `/docs/${temp[to.params.method]}/${to.params.id}`;
      //   }
      // },
      {
        path: '/service',
        redirect: (to, from) => {
          // console.log(to, from);
          return;
          // return "/member/curseleave"
        },
        meta: {
          noCache: true
        }
      },
      {
        path: '/error/book/110',
        redirect: (to, from) => {
          return "/docs/rule/60"
        },
        meta: {
          noCache: true
        }
      },
      {
        path: '/error/book/109',
        redirect: (to, from) => {
          return "/docs/rule/54"
        },
        meta: {
          noCache: true
        }
      },
      {
        path: '/error/book/108',
        redirect: (to, from) => {
          return "/docs/rule/47"
        },
        meta: {
          noCache: true
        }
      },
      {
        path: '/error/book/107',
        redirect: (to, from) => {
          // console.log(to, from);
          return "/docs/rule/40"
        },
        meta: {
          noCache: true
        }
      }, {
        path: '/ON_ENG*',
        redirect: (to, from) => {
          window.location = "https://exam.magee.tw/index.php"
        },
        meta: {
          noCache: true
        }
      }, {
        path: '/online*',
        redirect: (to, from) => {
          window.location = "https://vclass.magee.tw/";
        },
        meta: {
          noCache: true
        }
      }, {
        path: '/web-api/public/openmember/:key',
        redirect: (to, from) => {
          window.location = "http://www.magee.tw/api/public/openmember/" + to.params.key
        },
        meta: {
          noCache: true
        }
      }, {
        path: '/member/curseleave',
        redirect: (to, from) => {
          return "/member/application";
        },
        meta: {
          noCache: true
        }
      }
    ]
  }
];

export default paths
