import Vue from "vue";
import App from "@/App.vue";
// const app = createApp({});
// app.directive("context", {
// 	inserted: (el, binding) => {
// 		el.innerHTML = binding.value.replace(/(?:\r\n|\r|\n)/g, "</br>");
// 	},
// 	update: (el, binding) => {
// 		el.innerHTML = binding.value.replace(/(?:\r\n|\r|\n)/g, "</br>");
// 	},
// 	componentUpdated: (el, binding) => {
// 		el.innerHTML = binding.value.replace(/(?:\r\n|\r|\n)/g, "</br>");
// 	},
// });
Vue.directive('context', {
  inserted: (el, binding) => {
    el.innerHTML=binding.value.replace(/(?:\r\n|\r|\n)/g,"</br>");
  },
  update: (el, binding) => {
    el.innerHTML=binding.value.replace(/(?:\r\n|\r|\n)/g,"</br>");
  },
  componentUpdated: (el,binding) => {
    el.innerHTML=binding.value.replace(/(?:\r\n|\r|\n)/g,"</br>");
  }
});
